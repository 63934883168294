import { NavLink, Outlet } from "react-router-dom";
import React, { useContext, useState, useRef, useEffect } from 'react';
import { AuthContext } from "../AuthProvider";
import ChooseEsp from "../components/esp/ChooseEsp"
import logga from "../logga_med_text.png"

export default function Menu() {

    const { logout } = useContext(AuthContext);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleSettings = () => {
        setSettingsOpen(!settingsOpen);
    }

    const handleLogout = async () => {
        try {
            await logout(); // Call the logout function from the context
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest('.settings-menu')) {
                setSettingsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div id="detail">
                <div className="header" ref={menuRef}>
                    <div className="settings-container">
                        <NavLink to="/" ><img src={logga} alt="logga" style={{height: '50px', margin: '0 15px 0 0'}} /></NavLink>
                        <ChooseEsp />
                    </div>
                    <div className="settings-container">
                        <button className="settings-toggle" onClick={toggleSettings}>
                            <i className="bi bi-three-dots" />
                        </button>
                        {settingsOpen && (
                            <div className="settings-menu">
                                <NavLink to="/" onClick={toggleSettings}>Smart styrning</NavLink>
                                <NavLink to="/account" onClick={toggleSettings}>Konto</NavLink>
                                <NavLink to="/statistics" onClick={toggleSettings}>Statistik</NavLink>
                                <NavLink to="/" onClick={handleLogout}>Logga ut</NavLink>
                            </div>
                        )}
                    </div>
                </div>
                <Outlet />
            </div>
        </>
    );
}