import React, { useState, useContext } from "react";
import { Form, Button, Collapse, Modal, Spinner } from "react-bootstrap";
import { AuthContext } from "../AuthProvider";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast from "react-hot-toast";
import Parse from 'parse';
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";

const Away = () => {

    const [open, setOpen] = useState(true);

    const [showHelp, setShowHelp] = useState(false);
    const navigate = useNavigate();

    const handleCloseHelp = () => {
        setShowHelp(false);
    }

    const { esp, updateUserEsp, setUserEsp, espLoading } = useContext(AuthContext);

    const saveSelectedDate = (date) => {
        const now = new Date();
        if (date > now) {
            updateUserEsp(esp.id, { awayEnds: date })
                .then(Parse.Cloud.run("updateHotWaterHours", { espId: esp.id })
                    .then(Parse.Cloud.run("updateHeatingSchedule", { espId: esp.id })
                        .then((result) => setUserEsp(result))));
            setOpen(false);
        } else {
            toast.error("Planerad hemkomst måste vara i framtiden.");
        }

    }

    const clearDate = () => {
        updateUserEsp(esp.id, { awayEnds: null })
            .then(Parse.Cloud.run("updateHotWaterHours", { espId: esp.id })
                .then(Parse.Cloud.run("updateHeatingSchedule", { espId: esp.id })
                    .then((result) => setUserEsp(result))));
        setOpen(false);
    };

    return (
        <div className="detail-container">
            <div className="card p-3 mb-3">
                <div className="d-flex align-items-center" onClick={() => navigate("/")}>
                    <div className="d-flex align-items-center">
                        {espLoading && (<Spinner animation="border" role="status"></Spinner>)}
                        {!espLoading && !esp && (<h5>Ingen enhet kopplad</h5>)}
                    </div>
                    <i
                        className={"bi bi-arrow-left-short"}
                        style={{ cursor: "pointer", fontSize: '35px', fontWeight: 'bold' }}
                    />
                </div>
                {espLoading && (<Spinner animation="border" role="status"></Spinner>)}
                {!espLoading && !esp && (<h5>Ingen enhet kopplad</h5>)}
                {esp && (<h5 className="mb-0">{esp?.awayEnds ? (<i className="bi bi-luggage-fill text-primary" />) : (<i className="bi bi-luggage-fill text-muted" />)}&nbsp;&nbsp;
                    {esp?.awayEnds ? (
                        <>Bortaläge slutar: {format(new Date(esp.awayEnds), 'd MMM HH:mm')}</>
                    ) : (
                        <>Bortaläge ej aktivt</>
                    )}</h5>)}

                <div className="mt-3">
                    <Form>
                        <Form.Group controlId="dateTimePicker">
                            Planerad hemkomst:&nbsp;
                            <DatePicker
                                selected={esp?.awayEnds}
                                onChange={(date) => saveSelectedDate(date)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="yyyy-MM-dd HH:mm"
                                placeholderText="Välj datum och tid"
                                className="form-control"
                            />
                            &nbsp;
                            {esp?.awayEnds && (
                                < Button variant="danger" onClick={clearDate}>
                                    Avsluta bortaläge
                                </Button>
                            )}
                            <i className="bi bi-info-circle ms-2 mb-2" onClick={() => setShowHelp(true)} style={{ cursor: 'pointer', color: '#007bff' }} />
                        </Form.Group>
                    </Form>

                </div>
            </div >
            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Bortaläge</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Ställ in datum för hemkomst för att starta bortaläge och spara på el medan du är iväg. Värmen kommer hållas på nivån inställd under "bortrest" och varmvattnet kommer vara avstängt. Värme och varmvatten kommer att startas igång för att värmas vid billigast möjliga tillfälle och vara redo när du kommer hem.
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Away;
