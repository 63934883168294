import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Menu from "./routes/menu";
import Login from "./routes/login/login";
import Signup from "./routes/login/signup";
import RequestPasswordReset from "./routes/login/requestpasswordreset";
import ErrorPage from "./error-page";
import Statistics from "./routes/statistics";
import Account from "./routes/account";
import { Toaster } from "react-hot-toast";
import ResetPassword from "./routes/login/resetpassword";
import LoginMenu from "./routes/login/loginmenu";
import "bootstrap-icons/font/bootstrap-icons.css";
import ControlPanel from "./routes/controlpanel";
import { AuthProvider, AuthContext } from "./AuthProvider";
import Controlunits from "./routes/controlunits";
import Heating from "./routes/heating";
import Hotwater from "./routes/hotwater";
import Away from "./routes/away";
import { PriceProvider } from "./PriceProvider";
import Prices from "./routes/prices";
import Weather from "./routes/weather";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  } else if (user) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
};

function App() {
  return (
    <>
      <Toaster />
      <AuthProvider>
        <PriceProvider>
          <Routes errorElement={<ErrorPage />}>
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route exact path="/login" element={<LoginMenu />}>
              <Route index="true" element={<Login />} />
              <Route path="/login/signup" element={<Signup />} />
              <Route
                path="/login/requestreset"
                element={<RequestPasswordReset />}
              />
            </Route>
            <Route exact path="/" element={<Menu />}>
              <Route
                index="true"
                element={
                  <ProtectedRoute>
                    <ControlPanel />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/controlunits"
                element={
                  <ProtectedRoute>
                    <Controlunits />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/account"
                element={
                  <ProtectedRoute>
                    <Account />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/statistics"
                element={
                  <ProtectedRoute>
                    <Statistics />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/heating"
                element={
                  <ProtectedRoute>
                    <Heating />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/hotwater"
                element={
                  <ProtectedRoute>
                    <Hotwater />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/away"
                element={
                  <ProtectedRoute>
                    <Away />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/prices"
                element={
                  <ProtectedRoute>
                    <Prices />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/weather"
                element={
                  <ProtectedRoute>
                    <Weather />
                  </ProtectedRoute>
                }
              ></Route>
            </Route>
          </Routes>
        </PriceProvider>
      </AuthProvider>
    </>
  );
}

export default App;
