import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import toast from "react-hot-toast";
import { AuthContext } from "../../AuthProvider";


const ERROR_MESSAGES = {
    101: "Okänd användare eller fel lösenord"
};

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useContext(AuthContext);

    const onLogin = (e) => {
        e.preventDefault();
        login(username, password)
            .then((user) => {
                // Signed in
                navigate("/")
            })
            .catch((error) => {
                console.log("errorcode: " + error.code);
                console.log("error: " + error);
                const message = error.code
                    ? ERROR_MESSAGES[error.code]
                    : undefined;

                toast.error(message ? message : "Ett okänt fel har uppstått");
                setUsername("");
                setPassword("");
            });

    }

    return (
        <div className="detail-container">
            <div className="card p-3 mb-3">
                <form onSubmit={onLogin}>
                    <div className="logininput">

                        <label htmlFor="username">
                            E-postadress
                        </label>
                        <input
                            id="username"
                            name="username"
                            type="email"
                            required
                            placeholder="E-postadress"
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>

                    <div className="logininput">
                        <label htmlFor="password">
                            Lösenord
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            required
                            placeholder="Lösenord"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div className="logininput">
                        <button className="loginbutton"
                            onClick={onLogin}
                            type="submit"
                            disabled={username && password ? false : true}
                        >
                            Logga in
                        </button>

                    </div>
                </form>
            </div>
        </div >

    )
}

export default Login;