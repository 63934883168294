import { NavLink, Outlet } from "react-router-dom";
import React, { useState } from 'react';

export default function LoginMenu() {

    // const [menuOpen, setMenuOpen] = useState(false);

    // const toggleMenu = () => {
    //     setMenuOpen(!menuOpen);
    // }

    // const closeMenu = () => {
    //     setMenuOpen(false);
    // };

    return (
        <>
            <div id="detail">
                <div className="header">
                    {/* <button className="settings-toggle" onClick={toggleMenu}>
                        <i className="bi bi-sliders2" />
                    </button> */}
                    {/* {menuOpen && (
                        <div className="settings-menu">
                            <NavLink to="/login" onClick={closeMenu}>
                                <i className="bi bi-box-arrow-in-right" />
                                Logga in
                            </NavLink>
                        </div>
                    )} */}
                </div>
                <Outlet />
            </div>
        </>
    );
}