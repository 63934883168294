import { Spinner } from "react-bootstrap";
import React, { useContext } from "react";
import { AuthContext } from "../AuthProvider";
import AutomationStatus from "../components/hotwater/AutomationStatus";
import HeatingSchedule from "../components/heating/HeatingSchedule";
import Mode from "../components/heating/Mode";
import TempSettings from "../components/heating/TempSettings";
import { useNavigate } from "react-router-dom";
import { PriceContext } from "../PriceProvider";


const Prices = () => {


    const navigate = useNavigate();
    const {currentPrice, lowestPrice, highestPrice, meanPrice} = useContext(PriceContext);

    return (
        <div className="detail-container">
            <div className="card p-3 mb-3">
            <div className="d-flex align-items-center" onClick={() => navigate("/")}>
                    <i
                    className={"bi bi-arrow-left-short"} 
                    style={{ cursor: "pointer", fontSize: '35px', fontWeight: 'bold' }}
                />
                </div>
                <div className="mt-3">
                    

                <h5 className="mb-0">
                        <span><i className="bi bi-lightning-charge-fill" />&nbsp;Aktuella elpriser</span>
                        &nbsp;&nbsp;
                        <span title="Elpris denna timme" style={{ whiteSpace: 'nowrap' }}><i className="bi bi-clock" />&nbsp;{currentPrice} kr</span>
                        &nbsp;&nbsp;
                        <span title="Periodens lägsta elpris" style={{ whiteSpace: 'nowrap' }}><i className="bi bi-arrow-down text-success" />&nbsp;{lowestPrice} kr</span>
                        &nbsp;&nbsp;
                        <span title="Periodens genomsnittspris" style={{ whiteSpace: 'nowrap' }}><i className="bi bi-arrow-right text-warning" />&nbsp;{meanPrice} kr</span>
                        &nbsp;&nbsp;
                        <span title="Periodens högsta elpris" style={{ whiteSpace: 'nowrap' }}><i className="bi bi-arrow-up text-danger" />&nbsp;{highestPrice} kr</span>
                    </h5>

                        
                </div>
            </div>
        </div >

    )
}

export default Prices;


