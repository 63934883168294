// src/components/GrafanaDashboard.js
import React from 'react';

const GrafanaDashboard = () => {
  return (
    <div>
      <iframe
        title="Värmestyrning" 
        src="https://grafana.zippert.se/api/hassio_ingress/y0qHdR2XrCogPQ6haPVhoeNu_4dVNJaPe2RB8g5BosQ/d-solo/fdk6uushryi9sb/grona-utposten?orgId=1&from=1714450073497&to=1714471673497&theme=light&panelId=1"
        width="50%"
        height="400"
        frameBorder="0"
      />
    </div>
  );
};

export default GrafanaDashboard;
