import React, { useContext } from 'react';
import GrafanaDashboard from "../components/statistics/GrafanaDashboard"
import { AuthContext } from '../AuthProvider';

const Home = () => {

    const { esp, espLoading } = useContext(AuthContext);
    
    
    return (
        <div className="detail-container">
            {!espLoading && !esp && <p>Du har ingen värmestyrningsenhet kopplad till ditt kundkonto.</p>}
            {/* Värmestyrning av eller på */}
            {esp && (<div>
                <h4>Statistik för {esp.customerChosenName}</h4>
                <GrafanaDashboard /></div>)}
        </div>
    )
}

export default Home;


