import { Spinner } from "react-bootstrap";
import React, { useContext } from "react";
import { AuthContext } from "../AuthProvider";
import AutomationStatus from "../components/hotwater/AutomationStatus";
import HeatingSchedule from "../components/heating/HeatingSchedule";
import Mode from "../components/heating/Mode";
import TempSettings from "../components/heating/TempSettings";
import { useNavigate } from "react-router-dom";


const Heating = () => {

    const { esp, espLoading } = useContext(AuthContext);
    const navigate = useNavigate();

    return (
        <div className="detail-container">
            <div className="card p-3 mb-3">
            <div className="d-flex align-items-center" onClick={() => navigate("/")}>
                    <div className="d-flex align-items-center">
                        {espLoading && (<Spinner animation="border" role="status"></Spinner>)}
                        {!espLoading && !esp && (<h5>Ingen enhet kopplad</h5>)}
                    </div>
                    <i
                    className={"bi bi-arrow-left-short"} 
                    style={{ cursor: "pointer", fontSize: '35px', fontWeight: 'bold' }}
                />
                </div>
                <div className="mt-3">
                    {!espLoading && !esp && <p>Du har ingen värmestyrningsenhet kopplad till ditt kundkonto.</p>}
                    {/* Värmestyrning av eller på */}
                    {esp && (
                        <div>
                            {esp && (<>
                        <h5 className="mb-0">{
                            esp.heating?.automationStatus ? (
                                <><i className="bi bi-fire text-warning" />&nbsp;<span>Smart värmestyrning på</span></>
                            ) : (
                                <><i className="bi bi-fire text-muted" />&nbsp;<span>Smart värmestyrning av</span></>
                            )
                        } &nbsp;&nbsp;
                            <span title="Nuvarande inomhustemperatur" style={{ whiteSpace: 'nowrap' }}><i className="bi bi-thermometer"/>&nbsp;{esp?.sensorData?.indoorTemp}°C</span>
                        &nbsp;&nbsp;
                            <span title="Måltemperatur för värmesystem" style={{ whiteSpace: 'nowrap' }}><i className="bi bi-bullseye"/>&nbsp;{esp?.heating?.currentGoalTemp}°C</span>
                        </h5>
                    </>
                    )}
                            <AutomationStatus />
                            <Mode />
                            <TempSettings />
                            <HeatingSchedule />
                        </div>)}
                </div>
            </div>
        </div >

    )
}

export default Heating;


