import React, { useContext, useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Modal } from "react-bootstrap";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  Line,
  ReferenceLine,
} from 'recharts';
import { AuthContext } from "../../AuthProvider";
import Parse from 'parse';


const HotWaterSchedule = () => {
  const [showHelp, setShowHelp] = useState(false);
  const { esp } = useContext(AuthContext);
  const schedule = esp?.hotWater?.heatingPlan?.slice(12) ?? [];
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleCloseHelp = () => {
    setShowHelp(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Parse.Cloud.run("getPrices");
        setData(result.slice(12));
      } catch (error) {
        console.error("Fel vid hämtning av data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const hasValidSchedule = Array.isArray(schedule) && schedule.length > 0;

  const now = new Date();

  const consolidatedData = Object.values(data.reduce((acc, price) => {
    try {
      const priceDate = new Date(price.time);

      const hour = format(priceDate, 'HH:mm');
      const dateTime = format(priceDate, 'yyyy-MM-dd HH:mm');
      const fullDate = format(priceDate, 'd MMM HH:mm');

      const scheduleEntry = schedule.find(entry =>
        format(new Date(entry.hour), 'yyyy-MM-dd HH:mm') === format(priceDate, 'yyyy-MM-dd HH:mm')
      );

      if (!acc[dateTime] || price.price > acc[dateTime].price) {
        acc[dateTime] = {
          hour,
          fullDate,
          price: Number(price?.price) || 0,
          calculatedTemperature: scheduleEntry?.calculatedTemperature,
          isHeating: scheduleEntry?.isHeating || false,
          startTemp: scheduleEntry?.startTemp,
          endTemp: scheduleEntry?.endTemp,
          heatingMinutes: scheduleEntry?.heatingMinutes,
          targetTemp: scheduleEntry?.targetSchedule?.temp,
          scheduledTimes: scheduleEntry?.scheduledTimes,
          awayEnds: format(priceDate, 'yyyy-MM-dd HH') === format(esp?.awayEnds, 'yyyy-MM-dd HH'),
          isCurrentHour: format(priceDate, 'yyyy-MM-dd HH') === format(now, 'yyyy-MM-dd HH'),
        };
      }

      return acc;
    } catch (error) {
      console.error("Error processing price:", error);
      return acc;
    }
  }, {})).sort((a, b) => new Date(a.hour) - new Date(b.hour));
  /*console.log("consolidatedData: " + JSON.stringify(consolidatedData));
  console.log(consolidatedData
    .filter(entry => entry.scheduledTimes && entry.scheduledTimes.length > 0)
    .map((entry, index) => (
      entry.fullDate
    )));*/

  // Definiera färgkonstanter
  const COLORS = {
    AWAY: '#4A90E2',  // En kall blå som signalerar bortaläge
    HEATING: '#FFB405', // Den gula/orange du specificerade
    TEMPERATURE: '#801818', // Den röda färgen för temperatur
    ELPRIS: '#bfbfbf', // Standardfärg för elpris
    ELPRIS_TOOLTIP: '#7D7D7D', // en mörkare grå för att synas bättre
    CURRENT: '#7D7D7D', // Grå färg för nuvarande timme
    SCHEDULE_TIME: '#E74C3C',    // En varm röd som matchar intensiteten
  };

  return (
    <>
      <div>
        <div className="d-flex align-items-center mt-3">
          <h5>Planerad värmning</h5>
          <i className="bi bi-info-circle ms-2 mb-2" onClick={() => setShowHelp(true)} style={{ cursor: 'pointer', color: '#007bff' }} />
        </div>

        {esp?.awayEnds && esp.awayEnds > new Date() && (<div className="mb-3 p-3 border rounded bg-light">
          <div className="mb-2">
            <i className="bi bi-luggage-fill me-2"></i>
            <span className="text-primary">Bortaläge slutar {format(new Date(esp.awayEnds), 'd MMM HH:mm')}</span>
          </div>
        </div>)}

        <div style={{ width: '100%', height: '400px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={consolidatedData}
              margin={{
                top: 40,
                right: window.innerWidth > 768 ? 20 : -30,
                left: window.innerWidth > 768 ? 20 : -30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="fullDate"
                tick={{ fontSize: 12 }}
                interval={window.innerWidth > 768 ? 2 : 5}
                allowDuplicatedCategory={false}
                tickFormatter={(value) => value.split(' ')[2]}
              />
              <YAxis
                yAxisId="left"
                label={{
                  value: window.innerWidth > 768 ? 'Pris (kr/kWh)' : '',
                  angle: -90,
                  position: 'insideLeft'
                }}
                //domain={[-0.2, 1.2]}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                label={{
                  value: window.innerWidth > 768 ? 'Temperatur (°C)' : '',
                  angle: 90,
                  position: 'insideRight'
                }}
                domain={[0, 60]}
              />
              <Tooltip
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    const currentIndex = consolidatedData.findIndex(item => item.fullDate === label);
                    const currentEntry = consolidatedData[currentIndex];
                    const prevEntry = currentIndex > 0 ? consolidatedData[currentIndex - 1] : null;

                    return (
                      <div className="custom-tooltip bg-white p-2 border border-gray-200 shadow-sm">
                        <p className="mb-1">{currentEntry?.fullDate}</p>
                        <p className="mb-0" style={{ color: COLORS.ELPRIS_TOOLTIP }}>
                          Elpris: {currentEntry?.price.toFixed(2)} kr/kWh
                        </p>
                        {payload.map((pld, index) => {
                          if (pld.name === "Temperatur") {
                            return (
                              <p
                                key={index}
                                className="mb-0"
                                style={{ color: COLORS.TEMPERATURE }}
                              >
                                {prevEntry?.calculatedTemperature
                                  ? `${prevEntry.calculatedTemperature.toFixed(1)}°C → ${currentEntry?.calculatedTemperature?.toFixed(1)}°C`
                                  : `${currentEntry?.calculatedTemperature?.toFixed(1)}°C`
                                }
                              </p>
                            );
                          }
                          return null;
                        })}
                        {currentEntry?.scheduledTimes?.map((time, index) => (
                          <p key={index} className="mb-0" style={{ color: COLORS.SCHEDULE_TIME }}>
                            Schemalagd tid: {format(new Date(time), 'HH:mm')}
                          </p>
                        ))}
                        {currentEntry?.awayEnds && (
                          <p className="mb-0" style={{ color: COLORS.AWAY }}>
                            Bortaläge slutar: {format(new Date(esp.awayEnds), 'HH:mm')}
                          </p>
                        )}
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Legend
                payload={[
                  { value: 'Elpris', type: 'rect', color: COLORS.ELPRIS },
                  { value: 'Planerad värmning', type: 'rect', color: COLORS.HEATING },
                  { value: 'Temperatur', type: 'line', color: COLORS.TEMPERATURE }
                ]}
              />

              <Bar
                yAxisId="left"
                dataKey="price"
                name="Elpris"
                fill={COLORS.ELPRIS}
              >
                {consolidatedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.isHeating ? COLORS.HEATING : COLORS.ELPRIS}
                  />
                ))}
              </Bar>
              {hasValidSchedule && (
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="calculatedTemperature"
                  name="Temperatur"
                  stroke={COLORS.TEMPERATURE}
                  dot={false}
                  strokeWidth={2}
                />
              )}
              {consolidatedData
                .filter(entry => entry.scheduledTimes && entry.scheduledTimes.length > 0)
                .map((entry, index) => (
                  <ReferenceLine
                    key={`ref-${index}`}
                    x={entry.fullDate}
                    yAxisId="left"
                    stroke={COLORS.SCHEDULE_TIME}
                    strokeWidth={2}
                  />
                ))}
              {consolidatedData
                .filter(entry => entry.awayEnds)
                .map((entry, index) => (
                  <ReferenceLine
                    key={`ref-${index}`}
                    x={entry.fullDate}
                    yAxisId="left"
                    stroke={COLORS.AWAY}
                    strokeWidth={2}
                  />
                ))}
              {consolidatedData
                .filter(entry => entry.isCurrentHour)
                .map((entry, index) => (
                  <ReferenceLine
                    key={`current-hour-${index}`}
                    x={entry.fullDate}
                    yAxisId="left"
                    stroke={COLORS.CURRENT}
                    strokeWidth={2}
                    label={{
                      value: 'Nu',
                      position: 'top',
                      fill: COLORS.CURRENT
                    }}
                  />
                ))}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div >
      <Modal show={showHelp} onHide={handleCloseHelp} centered>
        <Modal.Header closeButton>
          <Modal.Title>Tider för varmvatten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          De gula staplarna visar när värmning av vatten planeras. Den röda linjen är den uppskattade temperaturen på varmvattnet. En uppskattad sänkning vid förbrukning av vatten räknas bort vid de schemalagda klockslagen utifrån tidigare användardata. Schemat räknas om när temperaturen ändras.
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HotWaterSchedule; 
