import React, { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { PriceContext } from "../../PriceProvider";

const PriceButton = () => {
    const navigate = useNavigate();
    const {loading, currentPrice, priceCategory} = useContext(PriceContext);

    const handleClick = () => {
        navigate('/prices');
    };

    if(loading){
        return (
            <button onClick={handleClick} className="controlpanel-button">Laddar</button>
        )
        
    }
    return (
        <button onClick={handleClick} className="controlpanel-button">
            {priceCategory === "LOW" && <i className="bi bi-arrow-down-short text-success" style={{fontSize: '100px', position: 'absolute', top: '-45px' }} />}
            {priceCategory === "MIDDLE" && <i className="bi bi-arrow-right-short text-warning" style={{fontSize: '100px', position: 'absolute', top: '-45px' }} />}
            {priceCategory === "HIGH" && <i className="bi bi-arrow-up-short text-danger" style={{fontSize: '100px', position: 'absolute', top: '-45px' }} />}
            <span style={{ fontSize: '18px', position: 'absolute', top: '50px' }}>{currentPrice} kr</span>
            <span style={{ fontSize: '12px', position: 'absolute', bottom: '5px' }}>Elpris</span>
        </button>
    );
};

export default PriceButton;