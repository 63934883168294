import React, { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../AuthProvider";

const WeatherButton = () => {
    const navigate = useNavigate();
    const { esp } = useContext(AuthContext);

    const handleClick = () => {
        navigate('/weather');
    };

    return (
        <button onClick={handleClick} className="controlpanel-button">
            <i className="bi bi-cloud" style={{ color: 'LightBlue', fontSize: '100px', position: 'absolute', top: '-35px' }} />
            <span style={{ fontSize: '18px', position: 'absolute', top: '40px' }}>1°C</span>
            <span style={{ fontSize: '12px', position: 'absolute', bottom: '10px' }}>Utomhus</span>
        </button>
    );
};

export default WeatherButton;