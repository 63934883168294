import React, { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../AuthProvider";
import { format } from "date-fns";

const AwayButton = () => {
    const navigate = useNavigate();
    const { esp } = useContext(AuthContext);

    const handleClick = () => {
        navigate('/away');
    };

    return (
        <button onClick={handleClick} className="controlpanel-button">

            {esp?.awayEnds ?
                <>
                    <i className="bi bi-luggage" style={{ color: "CornflowerBlue", fontSize: '100px', position: 'absolute', top: '-25px' }} />
                    <span style={{ fontSize: '12px', position: 'absolute', top: '10px'}}>{format(new Date(esp.awayEnds), 'd/M HH:mm')}</span>
                    <span style={{ fontSize: '12px', position: 'absolute', bottom: '10px' }}>Hemkomst</span>
                </>
                :
                <>
                    <i className="bi bi-luggage" style={{ color: "LightGrey", fontSize: '100px', position: 'absolute', top: '-25px' }} />
                    <span style={{ fontSize: '14px', position: 'absolute', bottom: '10px' }}>Åka bort?</span>
                </>
            }

        </button>
    );
};

export default AwayButton;