import React, { createContext, useState, useEffect } from "react";
import Parse from "parse";
import { format } from "date-fns";

const PriceContext = createContext(null);

const PriceProvider = ({ children }) => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [highestPrice, setHighestPrice] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [lowestPrice, setLowestPrice] = useState(null);
  const [meanPrice, setMeanPrice] = useState(null);
  const [priceCategory, setPriceCategory] = useState(null);

  const fetchPrices = async () => {
    try {
      const result = await Parse.Cloud.run("getPrices");
      setPrices(result.slice(12));
    } catch (error) {
      console.error("Fel vid hämtning av data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  useEffect(() => {
    const calculatePrices = async () => {
      if (prices && prices.length > 0) {
        const sortedPrices = prices.sort((a, b) => a.price - b.price);
        setCurrentPrice(
          prices
            .find(
              (price) =>
                format(price.time, "yyyy-MM-dd HH") ===
                format(new Date(), "yyyy-MM-dd HH")
            )
            ?.price?.toFixed(2)
        );

        setHighestPrice(
          sortedPrices ? sortedPrices.slice(-1)[0]?.price?.toFixed(2) : null
        );
        setLowestPrice(
          sortedPrices ? sortedPrices[0]?.price?.toFixed(2) : null
        );
        if (sortedPrices) {
          const sum = sortedPrices.reduce((acc, curr) => acc + curr.price, 0);
          const mean = sum / sortedPrices.length;
          setMeanPrice(mean.toFixed(2));
        }
      }
    };
    calculatePrices();
    const now = new Date();
    const delay = (60 - now.getMinutes()) * 60 * 1000 - now.getSeconds() * 1000; // Beräkna fördröjning till nästa hel timme

    const timer = setTimeout(() => {
      // Kör vid minuttal 0 och sedan varje timme
      calculatePrices();
      // Starta intervallet för att köra varje hel timme
      const interval = setInterval(() => {}, 3600000); // 3600000 ms = 1 timme

      return () => clearInterval(interval); // Rensa intervallet vid avmontering
    }, delay);

    return () => clearTimeout(timer); // Rensa timeout vid avmontering
  }, [prices]);

  useEffect(() => {
    const sortedPrices = prices.sort((a, b) => a.price - b.price);
    const currentPriceValue = parseFloat(currentPrice);
    const thirdLength = Math.floor(sortedPrices.length / 3);
    if (currentPriceValue <= sortedPrices[thirdLength - 1]?.price) {
      setPriceCategory("LOW");
    } else if (currentPriceValue <= sortedPrices[2 * thirdLength - 1]?.price) {
      setPriceCategory("MIDDLE");
    } else {
      setPriceCategory("HIGH");
    }
  }, [currentPrice]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PriceContext.Provider
      value={{
        prices,
        loading,
        highestPrice,
        currentPrice,
        lowestPrice,
        priceCategory,
        meanPrice,
      }}
    >
      {children}
    </PriceContext.Provider>
  );
};

export { PriceContext, PriceProvider };
