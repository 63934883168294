import React, { useContext, useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";

const ChooseEsp = () => {

    const { esp } = useContext(AuthContext);

    const handleSelectChange = (event) => {
        toggleSettings();
    };

    const [settingsOpen, setSettingsOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleSettings = () => {
        setSettingsOpen(!settingsOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setSettingsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <button className="esp-select" onClick={toggleSettings}>
                <i className="bi bi-houses" />&nbsp;{esp?.customerChosenName}
            </button>
            {
                settingsOpen && (
                    <div className="settings-menu esp-select-menu" ref={menuRef}>
                        <NavLink to="/" onClick={toggleSettings}>{esp?.customerChosenName}</NavLink>
                        <NavLink to="/" onClick={toggleSettings}>+ Lägg till styrenhet</NavLink>
                    </div>
                )
            }
        </>
    );
};

export default ChooseEsp;
