import React, { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../AuthProvider";

const HeatingButton = () => {
    const navigate = useNavigate();
    const { esp } = useContext(AuthContext);

    const handleClick = () => {
        navigate('/heating');
    };

    return (
        <button onClick={handleClick} className="controlpanel-button">
            <i className="bi bi-house text-warning" style={{ fontSize: '100px', position: 'absolute', top: '-25px' }} />
            <span style={{ fontSize: '18px', position: 'absolute', top: '40px' }}>{esp?.sensorData?.indoorTemp?.toFixed(1)}°C</span>
            <span style={{ fontSize: '12px', position: 'absolute', bottom: '10px' }}>Inomhus</span>
        </button>
    );
};

export default HeatingButton;