import { Form, Modal } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { AuthContext } from "../../AuthProvider";
import Parse from "parse";

const HotWaterLowPrice = () => {
    const [showHelp, setShowHelp] = useState(false);
    const { esp, updateUserEsp, setUserEsp } = useContext(AuthContext);

    const handleCloseHelp = () => {
        setShowHelp(false);
    }

    const handlePriceLimitInput = (event) => {
        const newValue = parseFloat(event.target.value);
        const oldValue = esp.hotWater.lowPriceLimit;
        if (newValue > -1 && newValue < 10 && newValue <= oldValue + 1 && newValue >= oldValue - 1) {
                    updateUserEsp(esp.id, {hotWater: {lowPriceLimit: newValue}})
                        .then((result) => {
                            Parse.Cloud.run("updateHotWaterHours", { espId: esp.id })
                                .then((updateResult) => {
                                    setUserEsp(updateResult)
                                });
                        });
        }
    };

    return (
        <>
            <div className="mt-3">
                <Form className="temp-form border-top mb-4">
                    <div className="d-flex align-items-center mt-3">
                        <h5>Lågprisgräns</h5>
                        <i className="bi bi-info-circle ms-2 mb-2" onClick={() => setShowHelp(true)} style={{ cursor: 'pointer', color: '#007bff' }} />
                    </div>
                    <Form.Group controlId="hotwaterLowPriceLimit">

                        <Form.Label>
                            Värm vid pris under kr/kWh
                        </Form.Label>

                        <Form.Control
                            type="number"
                            disabled={esp?.hotWater?.automationStatus ? false : true}
                            min={0}
                            max={10}
                            step={0.1}
                            value={esp?.hotWater?.lowPriceLimit}
                            onChange={handlePriceLimitInput}
                        />
                    </Form.Group>
                </Form>

            </div>
            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Värm alltid under angivet pris</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Ställ in gränsen för lågpris där varmvattnet värms kontinuerligt när priset är lägre än denna gräns.
                </Modal.Body>
            </Modal>
        </>

    )
}

export default HotWaterLowPrice;


