import React, { useContext } from 'react';
import { AuthContext } from '../AuthProvider';

const Controlunits = () => {

    const { esp, espLoading } = useContext(AuthContext);

    return (
        <div className="detail-container">
            {!espLoading && !esp && <p>Du har ingen värmestyrningsenhet kopplad till ditt kundkonto.</p>}
            {/* Värmestyrning av eller på */}
            {esp && (<div>
                <p>eget namn: {esp.customerChosenName}</p>
                <p>topic: {esp.topicName}</p>
            </div>)}
        </div>
    )
}

export default Controlunits;