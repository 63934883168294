import React, { useContext, useState } from 'react';
import { format } from 'date-fns';
import { Modal } from "react-bootstrap";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  Line,
  ReferenceLine,
} from 'recharts';
import { AuthContext } from "../../AuthProvider";

const HeatingSchedule = () => {
  const [showHelp, setShowHelp] = useState(false);
  const { esp } = useContext(AuthContext);
  const schedule = esp?.heating?.heatingSchedule?.slice(12) ?? [];
  const now = new Date();

  const enrichedSchedule = schedule.map(entry => {
    const entryDate = new Date(entry.hour);
    return {
      ...entry,
      hour: format(entryDate, 'HH:mm'),
      fullDate: format(entryDate, 'd MMM HH:mm'),
      dateTime: format(entryDate, 'yyyy-MM-dd HH:mm'),
      isCurrentHour: format(entryDate, 'yyyy-MM-dd HH') === format(now, 'yyyy-MM-dd HH'),
      awayEnds: format(entryDate, 'yyyy-MM-dd HH') === format(esp?.awayEnds, 'yyyy-MM-dd HH')
    };
  });

  const handleCloseHelp = () => {
    setShowHelp(false);
  }

  const hasValidSchedule = Array.isArray(schedule) && schedule.length > 0;

  // Definiera färgkonstanter
  const COLORS = {
    AWAY: '#4A90E2',  // En kall blå som signalerar bortaläge
    MIN: '#E74C3C',    // En varm röd som matchar intensiteten
    IDEAL: '#FFB405',  // Den gula/orange du specificerade
    MAX: '#4FB06D',    // En mjuk grön som kompletterar gul
    CURRENT: '#2ECC71', // En frisk grön färg för att representera nuvarande temperatur
    DEFAULT: '#7D7D7D'  // En synlig grå färg för omarkerade staplar
  };

  return (
    <>
      <div>
        <div className="d-flex align-items-center mt-3">
          <h5>Planerad värmning</h5>
          <i className="bi bi-info-circle ms-2 mb-2" onClick={() => setShowHelp(true)} style={{ cursor: 'pointer', color: '#007bff' }} />
        </div>

        {esp?.awayEnds && esp.awayEnds > now && (<div className="mb-3 p-3 border rounded bg-light">
          <div className="mb-2">
            <i className="bi bi-luggage-fill me-2"></i>
            <span className="text-primary">Bortaläge slutar {format(new Date(esp.awayEnds), 'd MMM HH:mm')}</span>
          </div>
        </div>)}

        <div style={{ width: '100%', height: '400px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={enrichedSchedule}
              margin={{
                top: 40,
                right: window.innerWidth > 768 ? 20 : -30,
                left: window.innerWidth > 768 ? 20 : -30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="fullDate"
                tick={{ fontSize: 12 }}
                interval={window.innerWidth > 768 ? 2 : 5}
                allowDuplicatedCategory={false}
                tickFormatter={(value) => value.split(' ')[2]}
              />
              <YAxis
                yAxisId="left"
                label={{
                  value: window.innerWidth > 768 ? 'Pris (kr/kWh)' : '',
                  angle: -90,
                  position: 'insideLeft'
                }}
                //domain={[0, 5]}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                label={{
                  value: window.innerWidth > 768 ? 'Temperatur (°C)' : '',
                  angle: 90,
                  position: 'insideRight'
                }}
                domain={[0, 30]}
              />
              <Tooltip
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    const entry = enrichedSchedule.find(item => item.fullDate === label);
                    return (
                      <div className="custom-tooltip bg-white p-2 border border-gray-200 shadow-sm">
                        <p className="mb-1">{entry.fullDate}</p>
                        <p className="mb-0" style={{ color: '#666' }}>
                          Elpris: {entry.price.toFixed(2)} kr/kWh
                        </p>
                        <p className="mb-0" style={{ color: '#801818' }}>
                          Måltemperatur: {entry.goalTemp}°C
                        </p>
                        {entry.awayEnds && (<p className="mb-0" style={{ color: COLORS.AWAY }}>
                          Bortaläge slutar: {format(esp?.awayEnds, 'HH:mm')}
                        </p>)}
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Legend
                payload={[
                  { value: `Borta (${esp.heating.tempSettings.away.temp}°C)`, type: 'rect', color: COLORS.AWAY },
                  { value: `Min (${esp.heating.tempSettings.min.temp}°C)`, type: 'rect', color: COLORS.MIN },
                  { value: `Ideal (${esp.heating.tempSettings.ideal.temp}°C)`, type: 'rect', color: COLORS.IDEAL },
                  { value: `Max (${esp.heating.tempSettings.max.temp}°C)`, type: 'rect', color: COLORS.MAX },
                  { value: `Måltemperatur`, type: 'line', color: COLORS.CURRENT }
                ]}
              />

              <Bar
                yAxisId="left"
                dataKey="price"
                name="Elpris"
                fill={COLORS.DEFAULT}
              >
                {enrichedSchedule.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.setting === "away" ? COLORS.AWAY : 
                          entry.setting === "min" ? COLORS.MIN : 
                          entry.setting === "ideal" ? COLORS.IDEAL : 
                          entry.setting === "max" ? COLORS.MAX : 
                          COLORS.DEFAULT}
                  />
                ))}
              </Bar>
              {hasValidSchedule && (
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="goalTemp"
                  name="Temperatur"
                  stroke={COLORS.CURRENT}
                  dot={false}
                  strokeWidth={2}
                />
              )}
              {enrichedSchedule
                .filter(entry => entry.isCurrentHour)
                .map((entry, index) => (
                  <ReferenceLine
                    key={`current-hour-${index}`}
                    x={entry.fullDate}
                    yAxisId="left"
                    stroke={COLORS.DEFAULT}
                    strokeWidth={2}
                    label={{ 
                      value: 'Nu',
                      position: 'top',
                      fill: COLORS.DEFAULT
                    }}
                  />
                ))}
              {enrichedSchedule
                .filter(entry => entry.awayEnds)
                .map((entry, index) => (
                  <ReferenceLine
                    key={`ref-${index}`}
                    x={entry.fullDate}
                    yAxisId="left"
                    stroke={COLORS.AWAY}
                    strokeWidth={2}
                    label={{ 
                      value: 'Hemkomst',
                      position: 'top',
                      fill: COLORS.AWAY,
                      offset: 20
                    }}
                  />
                ))}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div >
      <Modal show={showHelp} onHide={handleCloseHelp} centered>
        <Modal.Header closeButton>
          <Modal.Title>Tider för värmning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Eventuell hemkomst visas av det svarta strecket.
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HeatingSchedule; 
