import { Spinner } from "react-bootstrap";
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../AuthProvider";
import HotWaterTimes from "../components/hotwater/HotWaterTimes";
import AutomationStatus from "../components/hotwater/AutomationStatus";
import HotWaterStart from "../components/hotwater/HotWaterStart";
import HotWaterSchedule from "../components/hotwater/HotWaterSchedule";
import HotWaterLowPrice from "../components/hotwater/HotWaterLowPrice";
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";

const Hotwater = () => {

    const { esp, espLoading } = useContext(AuthContext);

    const navigate = useNavigate();
    const [currentHeatingPlan, setCurrentHeatingPlan] = useState(null);

    useEffect(() => {
        if (esp?.hotWater?.heatingPlan) {
            const currentPlan = esp.hotWater.heatingPlan.find((plan) => plan.isHeating);
            setCurrentHeatingPlan(currentPlan);
        }
    }, [esp]);

    return (
        <div className="detail-container">
            <div className="card p-3 mb-3">
                <div className="d-flex align-items-center" onClick={() => navigate("/")}>
                    <div className="d-flex align-items-center">
                        {espLoading && (<Spinner animation="border" role="status"></Spinner>)}
                        {!espLoading && !esp && (<h5>Ingen enhet kopplad</h5>)}
                    </div>
                    <i
                        className={"bi bi-arrow-left-short"}
                        style={{ cursor: "pointer", fontSize: '35px', fontWeight: 'bold' }}
                    />
                </div>
                <div className="mt-3">
                    {!espLoading && !esp && <p>Du har ingen styrenhet kopplad till ditt kundkonto.</p>}
                    {esp && (
                        <>
                            <h5 className="mb-0">{esp.hotWater?.automationStatus ? (
                                <><i className="bi bi-droplet-fill text-warning" />&nbsp;<span>Smart varmvattenstyrning på</span></>
                            ) : (
                                <><i className="bi bi-droplet-fill text-muted" />&nbsp;<span>Smart varmvattenstyrning av</span></>
                            )}
                                <span title="Nuvarande varmvattentemperatur" style={{ whiteSpace: 'nowrap' }}>&nbsp;&nbsp;<i className="bi bi-thermometer" />&nbsp;{esp?.sensorData?.hotWaterTemp}°C</span>
                                &nbsp;
                                <span title="Nästa planerade tidpunkt för att värma vatten" style={{ whiteSpace: 'nowrap' }}><i className="bi bi-clock" />&nbsp;{currentHeatingPlan ? format(currentHeatingPlan.hour, "HH:mm") : "Ingen schemalagd"}</span>
                            </h5>
                            <AutomationStatus />
                            <HotWaterStart />
                            <HotWaterLowPrice />
                            <HotWaterTimes />
                            <HotWaterSchedule />
                        </>
                    )}
                </div>
            </div>
        </div >

    )
}

export default Hotwater;


