import React, { useContext } from "react";

import HeatingSchedule from "../components/heating/HeatingSchedule";
import { AuthContext } from "../AuthProvider";
import HeatingButton from "../components/controlpanel/HeatingButton";
import HotWaterButton from "../components/controlpanel/HotWaterButton";
import AwayButton from "../components/controlpanel/AwayButton";
import WeatherButton from "../components/controlpanel/WeatherButton";
import PriceButton from "../components/controlpanel/PriceButton";

const ControlPanel = () => {

    const { esp } = useContext(AuthContext);

    return (
        <div className="detail-container">
            <div className="card p-3 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                    </div>
                </div>
                <div className="mt-3">
                    {esp && (
                        <div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex' }}>
                                    <PriceButton />
                                    <HeatingButton />
                                    <HotWaterButton />
                                    <WeatherButton />
                                </div>
                                <AwayButton style={{ marginLeft: 'auto' }} />
                            </div>
                            <HeatingSchedule />
                        </div>)}
                </div>

            </div>
        </div >

    )
}

export default ControlPanel;


