import { Spinner } from "react-bootstrap";
import React, { useContext } from "react";
import { AuthContext } from "../AuthProvider";
import { useNavigate } from "react-router-dom";


const Weather = () => {


    const navigate = useNavigate();

    return (
        <div className="detail-container">
            <div className="card p-3 mb-3">
            <div className="d-flex align-items-center" onClick={() => navigate("/")}>
                    <i
                    className={"bi bi-arrow-left-short"} 
                    style={{ cursor: "pointer", fontSize: '35px', fontWeight: 'bold' }}
                />
                </div>
                <div className="mt-3">
                    Kallt och otäckt är det ute. Bäst att stanna hemma och elda i kaminen.
                        
                </div>
            </div>
        </div >

    )
}

export default Weather;


